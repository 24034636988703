import { render, staticRenderFns } from "./ShangPinXXUpdateList.vue?vue&type=template&id=6f9039ca&scoped=true&"
import script from "./ShangPinXXUpdateList.vue?vue&type=script&lang=js&"
export * from "./ShangPinXXUpdateList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6f9039ca",
  null
  
)

export default component.exports