<template>
    <div class="x-page-container" ref="resize">
        <!-- 查询区 -->
        <div class="x-page-search">
            <x-search-item label="商品信息">
                <el-select size="small" v-model="searchSelect" slot="prepend" placeholder="请选择">
                    <el-option v-for="item in compoundOptions" :key="item.id" :label="item.name"
                               :value="item.id"></el-option>
                </el-select>
                <el-input v-model="searChParamMid" size="small">
                </el-input>
            </x-search-item>
            <x-search-item label="商品分类">
                <el-select size="small" @change="firstLevelChange" slot="prepend" v-model="firstLevelValue" placeholder="请选择">
                    <el-option-group
                            v-for="group in firstLevelOptions"
                            :key="group.hangYeMC"
                            :label="group.hangYeMC">
                        <el-option
                                v-for="item in group.list"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                        </el-option>
                    </el-option-group>
                </el-select>
                <el-select style="width: 138px" size="small" @change="secondLevelChange" v-model="secondLevelValue" v-if="secondIsShow">
                    <el-option v-for="item in secondLevelOptions" :key="item.id" :label="item.name"
                               :value="item.id"></el-option>
                </el-select>
            </x-search-item>
            <div class="x-search-item">
                <el-select style="width: 138px;" size="small" @change="thirdLevelChange" v-model="thirdLevelValue" v-if="thirdIsShow">
                    <el-option v-for="item in thirdLevelOptions" :key="item.id" :label="item.name"
                               :value="item.id"></el-option>
                </el-select>
            </div>
            <div class="x-search-item" style="margin-left: 5px">
                <el-button type="primary" size="mini" @click="handleSearch">搜索</el-button>
                <el-button type="primary" size="mini" @click="handleClear">清空</el-button>
            </div>
        </div>
        <!-- 批量按钮区 -->
        <div class="x-page-btn">
            <el-button type="success" size="mini" round @click="handleAdd">新增</el-button>
            <el-button v-show="menDianDisabled" type="primary" size="mini" round @click="handleSync">同步商户商品</el-button>
            <el-button v-show="menDianDisabled" type="primary" size="mini" round @click="handelUpdateList">更新商品信息</el-button>
        </div>
        <!-- 列表区域 -->
        <el-table ref="table" v-loading="table.loading" :data="table.data" :height="table.height" border
                  @selection-change="handleSelectionChange">
            <el-table-column width="50" label="序号" type="index" fixed/>
            <el-table-column label="商品信息">
                <template slot-scope="scope">
                    <p>名称：{{scope.row.shangPinMC}}</p>
                    <p>库存：{{scope.row.shangPinKuCun}}</p>
                </template>
            </el-table-column>
            <el-table-column label="编号信息">
                <template slot-scope="scope">
                    <div>
                        货号：{{scope.row.shangPinHuoHao}}
                    </div>
                    <div>
                        条码：{{scope.row.shangPinTiaoMa}}
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="价格信息(元)">
                <template slot-scope="scope">
                    <span>零售价：{{scope.row.dianNeiLSJ}}</span>
                </template>
            </el-table-column>
            <el-table-column label="状态信息">
                <template slot-scope="scope">
                    <div>
                        <span>是否上架：</span>
                        <el-switch @change="statusChange($event,scope.row)" v-model="scope.row.shangPinZT"
                                   active-value="true" inactive-value="false"></el-switch>
                    </div>
                    <div v-if="scope.row.guiShuMenDian">
                        <span>店内常用：</span>
                        <el-switch @change="statusChange($event,scope.row)" v-model="scope.row.shiFuDianNeiChangYong"
                                   active-value="true" inactive-value="false"></el-switch>
                    </div>
                    <div>
                        <span>店内出售：</span>
                        <el-switch @change="statusChange($event,scope.row)" v-model="scope.row.shiFuDianNeiChuShou"
                                   active-value="true" inactive-value="false"></el-switch>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="其他信息">
                <template slot-scope="scope">
                    <div>
                        <span>类别名称：{{scope.row.fenLeiMC}}</span>
                    </div>
                    <div>
                        <span>商品来源：{{scope.row.shangPinLaiYuan}}</span>
                    </div>
                </template>
            </el-table-column>
            <el-table-column width="250" align="center" label="操作" fixed="right">
                <template slot-scope="{ row, $index }">
                    <el-button type="primary" size="mini" round @click="handleUpdate(row.id)">编辑</el-button>
                    <el-popconfirm title="确认要删除该商品吗？" @confirm="handleDelete(row.id)">
                        <el-button type="danger" size="mini" round slot="reference">删除</el-button>
                    </el-popconfirm>
                </template>
            </el-table-column>
        </el-table>
        <!-- 分页区域 -->
        <el-pagination background
                       :total="table.total"
                       @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"
                       layout="total, sizes, prev, pager, next, jumper"/>
        <!-- 编辑页区域 -->
        <edit ref="edit" :editor="editor" @submitted="refresh"/>
        <shang-pin-x-x-sync ref="spsync" @submitted="refresh"/>
        <shang-pin-x-x-update-list ref="updateList" @submitted="refresh"/>
        <detail :detail="detail"/>
    </div>
</template>
<script>
    import * as service from "@/service/spgl/ShangPinXX";
    import {getFenLeiSelector} from "@/service/spgl/ShangPinFenLei"
    import XTableBase from "@/components/x/XTableBase";
    import Edit from "@/view/spgl/spxx/ShangPinXXEdit";
    import Detail from "@/view/spgl/spxx/ShangPinXXDetail";
    import ShangPinXXSync from "@/view/spgl/spxx/ShangPinXXSync"
    import ShangPinXXUpdateList from "@/view/spgl/spxx/ShangPinXXUpdateList";
    import {DICT_TYPE_USER_ORGAN_LX} from "@/util/constant"

    export default {
        name: "ShangPinXXList",
        mixins: [XTableBase],
        components: {Edit, Detail,ShangPinXXSync,ShangPinXXUpdateList},
        data() {
            this.refreshService = service.list;
            this.service = service;
            return {
                searChParamMid: '',
                compoundOptions: [
                    {id: 1, name: '名称'},
                    {id: 2, name: '货号'},
                    {id: 3, name: '条码'},
                ],
                firstLevelValue: '',
                secondLevelValue: '',
                thirdLevelValue: '',
                firstLevelOptions: [],
                secondLevelOptions: [],
                thirdLevelOptions: [],
                secondIsShow: false,
                thirdIsShow: false,
                menDianDisabled:false,
                searchSelect: 1,
                search: {
                    shangPinMC: '',
                    shangPinHuoHao: '',
                    shangPinTiaoMa: '',
                    shangPinLeiBie: '',
                    ownerOrganCode: '',
                }
            }
        },
        mounted() {
            this.getFirstLevelFenLei()
            this.currentIsMenDian()
        },
        methods: {
            handleSync(){
                this.$refs.spsync.syncVisible = true
            },
            handelUpdateList(){
                this.$refs.updateList.updateVisible = true
            },
            currentIsMenDian(){
               if(this.$store.getters.user.userOrganLx===DICT_TYPE_USER_ORGAN_LX.MD.key){
                   this.menDianDisabled = true
               }else{
                   this.menDianDisabled = false
               }
            },
            handleSearch() {
                if (this.searchSelect == 1) {
                    this.search.shangPinMC = this.searChParamMid
                } else if (this.searchSelect == 2) {
                    this.search.shangPinHuoHao = this.searChParamMid
                } else if (this.searchSelect == 3) {
                    this.search.shangPinTiaoMa = this.searChParamMid
                }
                this.searchNoClear.current = 1;
                return this.refresh();
            },
            //清空
            handleClear(){
                Object.keys(this.search).forEach((key) => this.search[key] = '')
                this.searChParamMid = ''
                this.firstLevelValue=''
                this.secondLevelValue = ''
                this.thirdLevelValue = ''
                this.secondLevelOptions = []
                this.thirdLevelOptions = []
                this.secondIsShow = false
                this.thirdIsShow = false
            },
            //获取商品第一级分类列表
            getFirstLevelFenLei() {
                getFenLeiSelector({}).then((response) => {
                    this.firstLevelOptions = response.data
                })
            },
            //三级商品类别数值变化后的处理方法
            firstLevelChange(val) {
                this.search.shangPinLeiBie = val
                this.getLowerLevelFenLei(val, 2)
            },
            secondLevelChange(val) {
                this.search.shangPinLeiBie = val
                this.getLowerLevelFenLei(val, 3)
            },
            thirdLevelChange(val) {
                this.search.shangPinLeiBie = val
                this.getLowerLevelFenLei(val, 2)
            },
            //获取下级分类
            getLowerLevelFenLei(id, level) {
                getFenLeiSelector({parentId: id, level: level}).then((response) => {
                    if (level == 2) {
                        this.secondLevelOptions = response.data
                        if (this.secondLevelOptions.length > 0) {
                            this.secondIsShow = true
                        } else {
                            this.secondIsShow = false
                        }
                        this.thirdIsShow = false
                        this.secondLevelValue = ""
                    } else {
                        this.thirdLevelOptions = response.data
                        if (this.thirdLevelOptions.length > 0) {
                            this.thirdIsShow = true
                        } else {
                            this.thirdIsShow = false
                        }
                        this.thirdLevelValue = ""
                    }
                })
            },
            //商品状态调整
            statusChange(val, row) {
                let updateData = {
                    id: row.id,
                    shangPinZT: row.shangPinZT,
                    shiFuDianNeiChuShou: row.shiFuDianNeiChuShou,
                    shiFuDianNeiChangYong: row.shiFuDianNeiChangYong
                }
                service.statusUpdate(updateData).then((response) => {
                    this.$message.success(response.msg);
                })
            },
        }
    }
</script>

<style scoped>
</style>
