<template>
    <el-dialog title="商品同步" :visible.sync="syncVisible" @close="cancel"
               destroy-on-close center append-to-body width="1200px">
        <div class="x-page-container" ref="resize">
            <el-collapse style="width: 100%">
                <el-collapse-item title="同步商品须知>>>" name="1">
                    <div>1.同步商户商品时，商品库存和商品进价是不会同步到门店的</div>
                    <div>2.一个商品在一个门店只能同步一次</div>
                </el-collapse-item>
            </el-collapse>
            <!-- 查询区 -->
            <div class="x-page-search">
                <x-search-item label="商品名称">
                    <el-select size="small" v-model="searchSelect" slot="prepend" placeholder="请选择">
                        <el-option v-for="item in compoundOptions" :key="item.id" :label="item.name"
                                   :value="item.id"></el-option>
                    </el-select>
                    <el-input v-model="searChParamMid" size="small">
                    </el-input>
                </x-search-item>
                <x-search-item label="商品分类">
                    <el-select size="small" @change="firstLevelChange" slot="prepend" v-model="firstLevelValue"
                               placeholder="请选择">
                        <el-option-group
                                v-for="group in firstLevelOptions"
                                :key="group.hangYeMC"
                                :label="group.hangYeMC">
                            <el-option
                                    v-for="item in group.list"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id">
                            </el-option>
                        </el-option-group>
                    </el-select>
                    <el-select style="width: 138px" size="small" @change="secondLevelChange" v-model="secondLevelValue"
                               v-if="secondIsShow">
                        <el-option v-for="item in secondLevelOptions" :key="item.id" :label="item.name"
                                   :value="item.id"></el-option>
                    </el-select>
                </x-search-item>
                <div class="x-search-item">
                    <el-select style="width: 138px;" size="small" @change="thirdLevelChange" v-model="thirdLevelValue"
                               v-if="thirdIsShow">
                        <el-option v-for="item in thirdLevelOptions" :key="item.id" :label="item.name"
                                   :value="item.id"></el-option>
                    </el-select>
                </div>
                <div class="x-search-item" style="margin-left: 5px">
                    <el-button type="primary" size="mini" @click="handleSearch">搜索</el-button>
                    <el-button type="primary" size="mini" @click="handleClear">清空</el-button>
                </div>
            </div>
            <div class="x-page-btn">
                <el-button type="primary" size="mini" round @click="handleBatchSync">批量同步</el-button>
            </div>
            <!-- 列表区域 -->
            <el-table ref="table" v-loading="table.loading" :data="table.data" :height="table.height" border
                      @selection-change="handleSelectionChange">
                <el-table-column type="selection" width="40" fixed/>
                <el-table-column width="50" label="序号" type="index" fixed/>
                <el-table-column prop="shangPinMC" label="商品分类"/>
                <el-table-column prop="fenLeiMC" label="商品分类"/>
                <el-table-column prop="shangPinHuoHao" label="商品货号"/>
                <el-table-column prop="shangPinTiaoMa" label="商品条码"/>
                <el-table-column prop="dianNeiLSJ" label="售价"/>
            </el-table>
            <!-- 分页区域 -->
            <el-pagination background
                           :total="table.total"
                           @size-change="handleSizeChange"
                           @current-change="handleCurrentChange"
                           layout="total, sizes, prev, pager, next, jumper"/>
        </div>
    </el-dialog>
</template>

<script>
    import * as service from "@/service/spgl/ShangPinXX";
    import {getFenLeiSelector} from "@/service/spgl/ShangPinFenLei"
    import {getCurrentMenDianXXFromRedis} from "@/service/mdgl/MenDianXX"
    import XTableBase from "@/components/x/XTableBase";
    import {LOCAL_STORAGE} from "@/util/storage";

    export default {
        name: "ShangPinXXSync",
        mixins: [XTableBase],
        beforeCreate() {
        },
        data() {
            this.refreshService = service.syncList;
            this.service = service;
            return {
                syncVisible: false,
                searChParamMid: '',
                compoundOptions: [
                    {id: 1, name: '名称'},
                    {id: 2, name: '货号'},
                    {id: 3, name: '条码'},
                ],
                firstLevelValue: '',
                secondLevelValue: '',
                thirdLevelValue: '',
                firstLevelOptions: [],
                secondLevelOptions: [],
                thirdLevelOptions: [],
                secondIsShow: false,
                thirdIsShow: false,
                searchSelect: 1,
                search: {
                    shangPinMC: '',
                    shangPinHuoHao: '',
                    shangPinTiaoMa: '',
                    shangPinLeiBie: '',
                }
            }
        },
        mounted() {
            this.getFirstLevelFenLei()
        },
        methods: {
            refresh() { //默认刷新列表方法
                let id = LOCAL_STORAGE.get("menDianId");
                if (!id) {
                    return;
                }
                this.table.loading = true;
                return this.refreshService(Object.assign(this.search, this.searchNoClear)).then((response) => {
                    let ret = response.data;
                    this.table.data = ret.records;
                    this.table.total = ret.total || 0;
                    //处理11条数据删除一条不显示问题
                    if (this.searchNoClear.current !== 1 && (!ret.records || ret.records === 0)) {
                        this.searchNoClear.current--
                        this.refresh()
                        return
                    }
                    return response;
                }).catch(error => {
                    throw error;
                }).finally(() => this.table.loading = false)
            },
            cancel() {
                this.handleClear()
                this.$emit("submitted");
                this.syncVisible = false
            },
            handleSearch() {
                if (this.searchSelect == 1) {
                    this.search.shangPinMC = this.searChParamMid
                } else if (this.searchSelect == 2) {
                    this.search.shangPinHuoHao = this.searChParamMid
                } else if (this.searchSelect == 3) {
                    this.search.shangPinTiaoMa = this.searChParamMid
                }
                this.searchNoClear.current = 1;
                return this.refresh();
            },
            //获取商品第一级分类列表
            getFirstLevelFenLei() {
                getFenLeiSelector({}).then((response) => {
                    this.firstLevelOptions = response.data
                })
            },
            //三级商品类别数值变化后的处理方法
            firstLevelChange(val) {
                this.search.shangPinLeiBie = val
                this.getLowerLevelFenLei(val, 2)
            },
            secondLevelChange(val) {
                this.search.shangPinLeiBie = val
                this.getLowerLevelFenLei(val, 3)
            },
            thirdLevelChange(val) {
                this.search.shangPinLeiBie = val
                this.getLowerLevelFenLei(val, 2)
            },
            //获取下级分类
            getLowerLevelFenLei(id, level) {
                getFenLeiSelector({parentId: id, level: level}).then((response) => {
                    if (level == 2) {
                        this.secondLevelOptions = response.data
                        if (this.secondLevelOptions.length > 0) {
                            this.secondIsShow = true
                        } else {
                            this.secondIsShow = false
                        }
                        this.thirdIsShow = false
                        this.secondLevelValue = ""
                    } else {
                        this.thirdLevelOptions = response.data
                        if (this.thirdLevelOptions.length > 0) {
                            this.thirdIsShow = true
                        } else {
                            this.thirdIsShow = false
                        }
                        this.thirdLevelValue = ""
                    }
                })
            },
            //清空
            handleClear(){
                Object.keys(this.search).forEach((key) => this.search[key] = '')
                this.searChParamMid = ''
                this.firstLevelValue=''
                this.secondLevelValue = ''
                this.thirdLevelValue = ''
                this.secondLevelOptions = []
                this.thirdLevelOptions = []
                this.secondIsShow = false
                this.thirdIsShow = false
            },
            //批量同步商户商品
            handleBatchSync() {
                if (!this.sels || this.sels.length < 1) {
                    this.$message.warning("请至少选择一条数据！");
                    return false;
                }
                this.service.batchSyncAdd(this.sels).then((response) => {
                    this.$message.success(response.msg);
                    this.refresh()
                })
            },
        }
    }
</script>

<style scoped>

</style>
