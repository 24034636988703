<template>
    <el-dialog :title="title" :visible.sync="updateVisible" @open="open" @close="cancel"
               :close-on-click-modal="false" destroy-on-close center append-to-body width="1200px">
        <el-collapse style="width: 100%">
            <el-collapse-item title="更新商品须知>>>" name="1">
                <div>1.若该门店已同步了商户的A商品，商户修改了A商品，则A商品会出现在门店的更新商品列表里，门店可选择A商品进行更新操作</div>
            </el-collapse-item>
        </el-collapse>
        <div class="x-page-container" ref="resize">
            <!-- 查询区 -->
            <div class="x-page-search">
                <x-search-item label="商品" textWidth="220px">
                    <el-input v-model="searchValue" size="small" @change="inputChange">
                        <x-selector-one @change="selectChange" slot="prepend" v-model="searchCondition"
                                        style="width: 100px;" size="small" dictType="T_SELECT_CONDITION"/>
                    </el-input>
                </x-search-item>
                <x-search-item label="商品分类">
                    <el-cascader :show-all-levels="false" size="small" style="width: 100%"
                                 v-model="search.shangPinFenLei"
                                 :options="fenLeiOptions"
                                 :props="fenLeiProps">
                    </el-cascader>
                </x-search-item>
                <div class="x-search-item" style="margin-left: 5px">
                    <el-button type="primary" size="mini" @click="handleSearch">搜索</el-button>
                    <el-button type="primary" size="mini" @click="handleClear">清空</el-button>
                </div>
            </div>
            <div>
                <p>选择更新字段:</p>
                <div>
                    <x-check-group v-model="checkList" dict-type="T_SHANG_PIN_SHU_XING"/>
                </div>
            </div>
            <!-- 批量按钮区 -->
            <div class="x-page-btn">
                <el-button type="primary" size="mini" round @click="handleUpdate">批量更新</el-button>
            </div>
            <!-- 列表区域 -->
            <el-table ref="table" v-loading="table.loading" :data="table.data" :height="table.height" border
                      @selection-change="handleSelectionChange">
                <el-table-column type="selection" width="40" fixed/>
                <el-table-column width="50" label="序号" type="index" fixed/>
                <el-table-column width="150" label="商品名称" prop="shangPinMC"/>
                <el-table-column width="150" label="商品分类" prop="fenLeiMC"/>
                <el-table-column width="150" label="货号" prop="shangPinHuoHao"/>
                <el-table-column width="150" label="条码" prop="shangPinTiaoMa"/>
                <el-table-column width="150" label="品牌" prop="shangPinPinPaiMC"/>
                <el-table-column width="150" label="产地" prop="shangPinChanDi"/>
                <el-table-column width="150" label="售价" prop="dianNeiLSJ"/>
                <el-table-column width="150" align="center" label="类型">
                    <template slot-scope="{ row, $index }">
                        <span v-if="!isDuoGuiGe(row.shiFuDuoGuiGe)">普通商品</span>
                        <el-button v-else type="text" size="mini" round @click="handleToDetail(row.id)">规格信息</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页区域 -->
            <el-pagination background
                           :total="table.total"
                           @size-change="handleSizeChange"
                           @current-change="handleCurrentChange"
                           layout="total, sizes, prev, pager, next, jumper"/>
            <!-- 详情页区域 -->
            <detail :detail="detail"/>
        </div>
    </el-dialog>
</template>
<script>
    import * as service from "@/service/spgl/ShangPinXX";
    import XTableBase from "@/components/x/XTableBase";
    import Detail from "@/view/spgl/spxx/ShangPinGuiGeXXDetail"
    import {DICT_TYPE_SP_SEARCH_CONDITION,DICT_YES_OR_NO,DICT_TYPE_SHANG_PIN_SHU_XING} from '@/util/constant'
    import {mainTree} from "@/service/spgl/ShangPinFenLei"
    import {deepCopy, deepMerge} from "@/util/objects";

    export default {
        name: "ShangPinXXUpdateList",
        mixins: [XTableBase],
        components: {Detail},
        data() {
            this.refreshService = service.getUpdateList;
            this.service = service;
            return {
                search: {
                    huoHao: '',
                    tiaoMa: '',
                    shangPinMC: '',
                    shangPinFenLei: '',
                },
                title:"更新商品",
                searchCondition: DICT_TYPE_SP_SEARCH_CONDITION.HH.key,
                searchValue: "",
                checkList: [],
                updateVisible:false,
                fenLeiOptions: [],
                fenLeiProps: {
                    emitPath: false,
                    value: "id",
                    label: "name",
                    children: "subNodes",
                    checkStrictly: false,
                    multiple: false
                },
            }
        },
        methods: {
            open() {
                this.getFenLeiOptions()
                this.refresh()
            },
            cancel() {
                this.handleClear()
                this.$emit("submitted");
                this.updateVisible = false
            },
            isDuoGuiGe(val){
                if(DICT_YES_OR_NO.YES.key===val){
                    return true;
                }else{
                    return false;
                }
            },
            inputChange(val) {
                if (this.searchCondition === DICT_TYPE_SP_SEARCH_CONDITION.HH.key) {
                    this.search.huoHao = val
                } else if (this.searchCondition === DICT_TYPE_SP_SEARCH_CONDITION.TM.key) {
                    this.search.tiaoMa = val
                } else {
                    this.search.shangPinMC = val
                }
            },
            selectChange(val) {
                this.search.huoHao = ''
                this.search.tiaoMa = ''
                this.search.shangPinMC = ''
                this.searchValue = ''
            },
            handleClear() { //默认清空方法
                Object.keys(this.search).forEach((key) => this.search[key] = '')
                this.searchValue = ''
            },
            //获取分类
            getFenLeiOptions() {
                mainTree().then((response) => {
                    this.fenLeiOptions = response.data
                })
            },
            //批量更新
            handleUpdate(){
                let ids = this.getIds()
                let updateModel = this.getUpdateObject()
                if(ids){
                    let waitUpdateList = []
                    //获取所有选择的商品数据（包含所有规格商品的数据）
                    this.service.getAllUpdateSpList({ids:ids}).then((res)=>{
                        let shSpList = res.data
                        if(shSpList){
                            shSpList.forEach(item=>{
                                let updateObj = deepCopy(updateModel)
                                Object.keys(updateObj).forEach((key)=>{
                                    updateObj[key] = item[key]
                                })
                                waitUpdateList.push(updateObj)
                            })
                            this.service.batchUpdateShSp(waitUpdateList).then((response)=>{
                                this.$message.success(response.msg);
                                this.$emit("submitted");
                                this.refresh()
                            })
                        }
                    })
                }
            },
            //获取根据选择的列表组装的对象
            getUpdateObject(){
                if(!this.checkList || this.checkList<1){
                    this.$message.warning("请至少选择一个更新字段！");
                    return;
                }
                let obj = {id:'',shangPinBanBenHao:''}
                this.checkList.forEach(item=>{
                    obj[item] = item===DICT_TYPE_SHANG_PIN_SHU_XING.TP.key?[]:''
                    if(item===DICT_TYPE_SHANG_PIN_SHU_XING.HH.key){
                        obj[DICT_TYPE_SHANG_PIN_SHU_XING.GGHH.key] = ''
                    }else if(item===DICT_TYPE_SHANG_PIN_SHU_XING.TM.key){
                        obj[DICT_TYPE_SHANG_PIN_SHU_XING.GGTM.key] = ''
                    }else if(item===DICT_TYPE_SHANG_PIN_SHU_XING.LSJ.key){
                        obj[DICT_TYPE_SHANG_PIN_SHU_XING.GGLSJ.key] = ''
                    }else if(item===DICT_TYPE_SHANG_PIN_SHU_XING.GHS){
                        obj[DICT_TYPE_SHANG_PIN_SHU_XING.GHSMC.key] = ''
                    }else if(item===DICT_TYPE_SHANG_PIN_SHU_XING.FL){
                        obj[DICT_TYPE_SHANG_PIN_SHU_XING.FLMC.key] = ''
                    }else if(item===DICT_TYPE_SHANG_PIN_SHU_XING.PP){
                        obj[DICT_TYPE_SHANG_PIN_SHU_XING.PPMC.key] = ''
                    }
                })
                return obj;
            }
        }
    }
</script>

<style scoped>
</style>
