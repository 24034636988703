<template>
    <el-dialog title="详情" :visible.sync="detail.visible" @open="open"
        destroy-on-close center append-to-body width="700px">
        <x-detail label-width="140px" :entity="entity">
            <el-table ref="table" v-loading="table.loading" :data="table.data" :height="table.height" border>
                <el-table-column width="50" label="序号" type="index" fixed/>
                <el-table-column  label="商品名称" prop="shangPinMC"/>
                <el-table-column  label="规格货号" prop="guiGeHuoHao"/>
                <el-table-column  label="规格条码" prop="guiGeTiaoMa"/>
                <el-table-column  label="售价" prop="guiGeShangPinLSJ"/>
            </el-table>
        </x-detail>
    </el-dialog>
</template>

<script>
    import * as service from "@/service/spgl/ShangPinXX";
    import XDetailBase from "@/components/x/XDetailBase";

    export default {
        mixins: [XDetailBase],
        beforeCreate() {


        },
        data(){
            return {
                table: {
                    loading: false,
                    data: [],
                    total: 0,
                    height: 500
                },
                entity:{}
            }
        },
        methods:{
            open(){
                service.getUpdate(this.detail.id).then((res)=>{
                    if(res.data.guiGeShangPinList){
                        this.table.data =res.data.guiGeShangPinList
                        this.table.total = res.data.guiGeShangPinList.length
                    }
                })
            }
        }
    }
</script>

<style scoped>

</style>
